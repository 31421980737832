import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ContactImage from "../Assets/Images/Image box.png";
import axios from "axios";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";
import parse from "html-react-parser";

function Terms() {

  let [data, setData] = useState([]);
  const getData = async () => {
      try {
          const response = await axiosConfig.get(
            `https://admin.tejwal.co/api/info`,
            {}
          );
          setData(response.data.data);
          console.log(response.data)
      } catch (error) {
      }
  };
  
  useEffect(() => {
      getData();
  }, []);
  return (
    <div className="terms">
      <div className="container">
        <h1>Terms and Conditions</h1>
        {parse(`${data?.terms}`)}
      </div>
    </div>
  );
}

export default Terms;
