import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Assets/Css/Login.css";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { axiosConfig } from "./axiosAPI";

function ForgetPassword() {
  
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
  });
  
  const handleEmail = async (e) => {
    e.preventDefault();
    const response = await axiosConfig
      .post("https://admin.tejwal.co/api/forgot-password", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        navigate(`/OTPPassword/${formData.email}`);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  return (
    <>
      <div className="login">
        <form className="form" action="/OTP">
          <div className="row">
            <div className="col-md-12">
              <div className="forget">
                <Link to="/Login" className="back">
                  <i className="fa fa-chevron-left"></i>Back to login
                </Link>
                <h1>Forgot your password?</h1>
                <p>
                  Don’t worry, happens to all of us. Enter your email below to
                  recover your password
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Your email"
                  onChange={handleChange}
                ></input>
              </div>
            </div>
          </div>
          <button type="submit" className="submit" onClick={handleEmail}>
            Submit <i className="fa fa-chevron-right"></i>
          </button>
        </form>
      </div>
    </>
  );
}

export default ForgetPassword;
