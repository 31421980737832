import { useContext, createContext, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Google from "../Assets/Images/Google.png"
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';

function GoogleAuth() {
  const [user, setUser] = useState(null);
  const [personData, setPersonData] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("accessToken") || "");
  const navigate = useNavigate();


  const handleSuccess = async (tokenResponse) => {
    try {
      // Send the credential to your API
      const response = await axios.post(
        "https://admin.tejwal.co/api/social-login",
        {
          google_id: tokenResponse.access_token || tokenResponse.credential,
        },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

        setPersonData(response.data.data.user);
        setToken(response.data.data.access_token);
        localStorage.setItem("accessToken", response.data.data.access_token);
        localStorage.setItem("PersonData", response.data.data.user);
        navigate("/");
        
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleError = () => {
    console.error("Google login failed");
    alert("Login failed!");
  };

  const login = useGoogleLogin({
    onSuccess: tokenResponse => handleSuccess(tokenResponse),
    onError: tokenResponse => handleError(tokenResponse),
    useOneTap: true,
  });

  return (
    <div>
    <button onClick={() => login()} className="login-with-google">
      <img src={Google} alt="Google" className="me-3" />
      Sign in with Google 🚀
    </button>
  </div>
  );
}

export default GoogleAuth;
