import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { axiosConfig } from "../Login/axiosAPI";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { convertLength } from "@mui/material/styles/cssUtils";

const DynamicGridLayout = () => {
  const navigate = useNavigate();
    
    let [country, setCountry] = useState([]);
    const getCountry = async () => {
        try {
            const response = await axiosConfig.get(
              `https://admin.tejwal.co/api/countries`,
              {}
            );
            setCountry(response.data.data);
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
    };

  useEffect(() => {
    getCountry()
  }, []);
  return (
    <>
      {country.map((item, index) => (
        <div
          key={index}
          className="col-md-4"
        >
          <div className="country-card">
            <a
              onClick={(e) => {
                navigate(`/Destination/${item.id}`, {
                  state: { items: item },
                });
                // console.log(items)
              }}
            >
              <img
                src={`https://admin.tejwal.co/storage/${item.img1}`}
                alt={item.title}
              />
              <div className="country-layout">
                <h3>{item.title}</h3>
              </div>
            </a>
          </div>
        </div>
      ))}
    </>
  );
};

export default DynamicGridLayout;
