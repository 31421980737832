// import { useContext, createContext, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// const AuthContext = createContext();

// const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [token, setToken] = useState(localStorage.getItem("accessToken") || "");
//   const navigate = useNavigate();

//   const loginAction = async (e, data) => {
//     console.log("data", data)
//     try {
//       const response = await fetch(
//         "https://admin.tejwal.co/api/login",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(data),
//         }
//       );
//       const res = await response.json();
//       if (res) {
//         setUser(res.refreshToken);
//         setToken(res.accessToken);
//         localStorage.setItem("accessToken", res.accessToken);
//         localStorage.setItem("refreshToken", res.refreshToken);
//         navigate("/Login");
//         return;
//       }
//       throw new Error(res.message);
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   const auth = useAuth();
//   const logOut = async (data) => {
//     try {
//       const response = await fetch(
//         "https://admin.tejwal.co/api/logout",
//         {
//           method: "POST",
//           headers: {
//             Authorization: "Bearer " + auth.token,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       if (response) {
//         setUser(null);
//         setToken("");
//         localStorage.clear();
//         navigate("/Login");
//         return;
//       }
//       throw new Error(response.message);
//     } catch (err) {
//       console.error(err);
//     }
//   };


//   return (
//     <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthProvider;

// export const useAuth = () => {
//   return useContext(AuthContext);
// };












import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [personData, setPersonData] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("accessToken") || "");
  const navigate = useNavigate();
  
  const loginAction = async (data) => {
    try {
      const response = await fetch(
        "https://admin.tejwal.co/api/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const res = await response.json();
      if (res) {
        console.log(res.data.access_token);
        console.log(res.data.user);
        setUser(res.refreshToken);
        setPersonData(res.data.user);
        setToken(res.data.access_token);
        localStorage.setItem("accessToken", res.data.access_token);
        localStorage.setItem("PersonData", res.data.user);
        localStorage.setItem("refreshToken", res.refreshToken);
        console.log("PersonData", personData);
        navigate("/");
        return;
      }
      throw new Error(res.message);
    } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Email Or Password Wrong",
          text: "Something went wrong!",
        });
    }
  };


  const auth = useAuth();
  const logOut = async (data) => {
    try {
      const response = await fetch(
        "https://admin.tejwal.co/api/logout",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        setUser(null);
        setToken("");
        localStorage.clear();
        navigate("/");
        return;
      }
      throw new Error(response.message);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{ token, user, personData, loginAction, logOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
