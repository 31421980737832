import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../Assets/Css/Login.css";
import Touch from "../Pages/Get_In_Touch";
import Google from "../Assets/Images/Google.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { axiosConfig } from "../Login/axiosAPI";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function OTPPassword() {
    const { email: currentPath } = useParams();
    const [otp, newOtp] = useState();
    const [otpVal, setOtpVal] = useState(new Array(6).fill("")); // Array to store each digit
    const textBase = useRef(null);
  
    useEffect(() => {
      newOtp(Math.floor(1000 + Math.random() * 9000));
    }, []);
  
    const navigate = useNavigate();
  
    const [formData, setFormData] = useState({
      otp: "",
      email: currentPath,
    });
  
    const handleVerify = (e) => {
        e.preventDefault();
        const otpString = otpVal.join(""); 
        
        navigate("/NewPassword", {
          state: { otp: otpString, email: currentPath },
        });
      };


  
    const focusNext = (e, index) => {
      const value = e.target.value;
      const updatedOtpVal = [...otpVal];
      updatedOtpVal[index] = value; // Update the specific index with the input value
      setOtpVal(updatedOtpVal);
  
      // Automatically focus the next input
      if (value && index < otpVal.length - 1) {
        textBase.current.children[index + 1].focus();
      }
    };
  
    return (
      <div className="login">
        <form className="form" onSubmit={handleVerify}>
          <div className="row">
            <div className="col-md-12">
              <div className="forget">
                <Link to="/Login" className="back">
                  <i className="fa fa-chevron-left"></i>Back to login
                </Link>
                <h1>Enter OTP</h1>
                <p>
                  We have shared a code with your registered email address: <br />
                  {currentPath}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="base">
                <div className="otp-base" ref={textBase}>
                  {new Array(6).fill(null).map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={otpVal[index]}
                      onChange={(e) => focusNext(e, index)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button type="submit" className="submit">
            Verify <i className="fa fa-chevron-right"></i>
          </button>
        </form>
      </div>
    );
  }
  
  export default OTPPassword;
  
