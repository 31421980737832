import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../Assets/Css/Login.css";
import Touch from "../Pages/Get_In_Touch";
import Google from "../Assets/Images/Google.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { axiosConfig } from "../Login/axiosAPI";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function OTP() {
    const { email: currentPath } = useParams();
    const [otp, newOtp] = useState();
    const [verfied, setVerfied] = useState(false);
    const [otpVal, setOtpVal] = useState([]);
    const textBase = useRef(null);
    console.log(currentPath)

    // generate random otp for each first render

    useEffect(() => {
      newOtp(Math.floor(1000 + Math.random() * 9000));
    }, []);

    const navigate = useNavigate();
    
  const [formData, setFormData] = useState({
    otp: "12345",
    email: currentPath,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

      const handleVerify = async (e) => {
        e.preventDefault();
        const response = await axiosConfig
          .post("https://admin.tejwal.co/api/verify-otp", formData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            navigate("/Login");
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      };

    const clearAll = () => {
      textBase.current.classList.remove("otp-error");
      textBase.current.childNodes.forEach((child) => {
        child.value = "";
      });
      setOtpVal([]);
      setVerfied(false);
    };

    const getOtp = () => {
      if (parseInt(otpVal.join("")) === otp) {
        textBase.current.classList.remove("otp-error");
        setVerfied(true);
      } else {
        textBase.current.classList.add("otp-error");
      }
    };

    const focusNext = (e) => {
      const childCount = textBase.current.childElementCount;
      const currentIndex = [...e.target.parentNode.children].indexOf(e.target);
      if (currentIndex !== childCount - 1) {
        e.target.nextSibling.focus();
      } else {
        const values = [];
        textBase.current.childNodes.forEach((child) => {
          values.push(child.value);
        });
        if (values.length !== 0) {
          setOtpVal(values);
        }
      }
    };

    useEffect(() => {
      if (otpVal.length === textBase.current.childElementCount) {
        getOtp();
      }
    }, [otpVal]);
  return (
    <>
      <div className="login">
        <form className="form" onSubmit={handleVerify}>
          <div className="row">
            <div className="col-md-12">
              <div className="forget">
                <Link to="/Login" className="back">
                  <i className="fa fa-chevron-left"></i>Back to login
                </Link>
                <h1>Enter OTP</h1>
                <p>
                  We have share a code of your registered email address / <br />
                  {currentPath}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="base">
                    <div className="otp-base" ref={textBase}>
                      {new Array(5).fill(null).map((input) => {
                        return (
                          <input type="text" onChange={(e) => focusNext(e)} />
                        );
                      })}
                    </div>
              </div>
            </div>
          </div>
          <button onClick={handleVerify} className="submit">
            Verify <i className="fa fa-chevron-right"></i>
          </button>
        </form>
      </div>
    </>
  );
}

export default OTP;
