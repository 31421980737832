import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import cover from "../Assets/Images/Rectangle 34624198.png";
import img1 from "../Assets/Images/Rectangle 34624198 (2).png";
import img2 from "../Assets/Images/Rectangle 34624199.png";
import "../Assets/Css/Web.css";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";

function Destination() {
  const location = useLocation();
  const [post, setPost] = useState({});
  useEffect(() => {
    setPost(location.state.items);
  }, []);
  console.log(post);
  return (
    <>
      <div className="destination">
        <div className="container">
          <div>
            <h1>{post?.title}</h1>
            <p>{parse(`${post?.desc1}`)}</p>
            <img
              src={`https://admin.tejwal.co/storage/${post?.img1}`}
              alt="cover"
              className="cover"
            />
            <p>{parse(`${post?.desc2}`)}</p>
            <p>{parse(`${post?.desc3}`)}</p>
            <div className="destination-images">
              <img
                src={`https://admin.tejwal.co/storage/${post?.img2}`}
                alt="cover"
                className="me-2"
              />
              {/* <img src={img2} alt="cover" className="ms-2" /> */}
            </div>
            {/* <p>
              Welcome to Dubai, the epitome of luxury, innovation, and limitless
              possibilities. Nestled in the heart of the United Arab Emirates,
              this dynamic destination offers a captivating blend of tradition
              and modernity, captivating visitors from around the globe.Indulge
              in the opulence of Dubai's iconic skyline, adorned with
              architectural marvels like the Burj Khalifa, the world's tallest
              building.
            </p>
            <p>
              Welcome to Dubai, the epitome of luxury, innovation, and limitless
              possibilities. Nestled in the heart of the United Arab Emirates,
              this dynamic destination offers a captivating blend of tradition
              and modernity, captivating visitors from around the globe.Indulge
              in the opulence of Dubai's iconic skyline, adorned with
              architectural marvels like the Burj Khalifa, the world's tallest
              building.
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Destination;
