import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import "../Assets/Css/Navbar.css";
import Logo from "../Assets/Images/tejwallightlogo.png";
import { useAuth } from "../Login/AuthProvider";

function Navbar() {
    const [header, setHeader] = useState("header-home");
    const listenScrollEvent = (event) => {
        if (window.scrollY < 73) {
        return setHeader("header-home");
        } else if (window.scrollY > 270) {
        return setHeader("header-home2");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
        return () => window.removeEventListener("scroll", listenScrollEvent);
    }, []);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [open, setOpen] = useState(false);
    const container = useRef(null);

    const handleClickOutside = (event) => {
      if (container.current && !container.current.contains(event.target)) {
        setOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        // clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  const auth = useAuth();

  return (
    <header className={`${header} navbar en`}>
      <div className="container">
        <div className="nav">
          <div className="bars">
            <button
              type="button"
              className="button"
              onClick={() => setOpen(!open)}
            >
              <i className="fa fa-bars"></i>
            </button>
            {open && (
              <ul className="dropdown-wrapper">
                <li className="">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive
                        ? "active nav-link fromRight"
                        : "nav-link fromRight"
                    }
                  >
                    Home
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    to="/About"
                    className={({ isActive }) =>
                      isActive
                        ? "active nav-link fromRight"
                        : "nav-link fromRight"
                    }
                  >
                    About Us
                  </NavLink>
                </li>
                <li className=" ">
                  <NavLink
                    to="/Contact"
                    className={({ isActive }) =>
                      isActive
                        ? "active nav-link fromRight"
                        : "nav-link fromRight"
                    }
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
          <div className="nav-barnd">
            <img src={Logo} alt="logo" />
          </div>
          <div className="d-flex">
            <ul className="nav-links">
              <li className="">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  Home
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/About"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  About Us
                </NavLink>
              </li>
              <li className=" ">
                <NavLink
                  to="/Contact"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  Contact Us
                </NavLink>
              </li>
              {/* <li className=" ">
                <NavLink
                  to="/Login"
                  className={({ isActive }) =>
                    isActive
                      ? "active nav-link fromRight"
                      : "nav-link fromRight"
                  }
                >
                  Login
                </NavLink>
              </li> */}
            </ul>
            <ul className="nav-button">
              {!auth.token ? (
                <Link to="/Login" className="profile-button">
                  <i className="fa fa-user"></i>
                </Link>
              ) : (
                <Link to="/Profile" className="profile-button">
                  <i className="fa fa-user"></i>
                </Link>
              )}

              {/* <button type="button" className="cart-button position-relative">
                <i className="fa fa-cart-shopping"></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-black">
                  2<span className="visually-hidden">unread messages</span>
                </span>
              </button> */}
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
