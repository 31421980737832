import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import calendar from "../Assets/Images/arrow-3.png";
import global from "../Assets/Images/calendar.png";
import Data from "../Assets/Images/global.png";
import Dollar from "../Assets/Images/dollar-circle.png";
import "../Assets/Css/Web.css";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";
import { useAuth } from "../Login/AuthProvider";
import { useParams } from "react-router";
import Dropdown from "../Package/dropdown";
import Quantity from "../Package/Quantity";
import ModalCode from "../Package/Modal";

function Global() {
    let [data, setData] = useState([]);
    const [a, seta] = useState({});

    
  const w=(d)=>{
    console.log(d);
  }
    const getData = async () => {
      try {
        const response = await axiosConfig.get(
          `https://admin.tejwal.co/api/airalo/get-packages?type=global`
        );
        setData(response?.data?.data?.data);
        console.log(response.data.data.data);
      } catch (error) {}
    };

    useEffect(() => {
      getData();
    }, []);

    const result1 = data.filter((inner) => inner.slug === "world");

    console.log(result1);

  const auth = useAuth();
  console.log(auth.token);

  // const postPayment = async (p) => {
  //   console.log("pac", p); // we can access id now
  //   // console.log("response", response); // we can access id now
  //   // e.preventDefault();
  //   const response = await axiosConfig({
  //     method: "post",
  //     url: "https://admin.tejwal.co/api/get-payment-url",
  //     data: {
  //       quantity: 1,
  //       package_id: p.id,
  //       type: p.type,
  //       description: p.id,
  //       price: p.price,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + auth.token,
  //     },
  //   })
  //     .then((res) => {
  //       console.log(res.data.data);
  //       // window.location = `${res.data.data}`;
  //       // navigate("");
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "You Should Login First",
  //       });
  //     });
  // };

  const postPayment = async (p) => {
    console.log("pac", p);
    // console.log("code", d);
    seta(p)
  };
  

  return (
    <>
      <div className="row">
        {result1.map((x, index) => (
          <div className="col-md-12" key={index}>
            <div className="">
              {/* <h2>{x.title}</h2>
              <p>
                Our goverment backed plan designed to keep your business legaly
                and secure
              </p> */}
              {x.operators.map((y, index) => (
                <div key={index}>
                  {/* <div className="data">
                    <h3>
                      <span>
                        <img src={Data} alt="Global" />
                        Coverage
                      </span>
                      <strong className="number-of-countries">
                        {y.countries.length} Countries
                      </strong>
                    </h3>
                  </div> */}
                  <div className="row">
                    {y.packages.map((p, index) => (
                      <div className="col-md-4" key={index}>
                        <div className="">
                          <div className="global-package">
                            <div className="">
                              <h1>{p.title}</h1>
                            </div>
                            <div className="p-3">
                              <div className="data">
                                <h3>
                                  <span>
                                    <img src={Data} alt="Global" />
                                    Coverage
                                  </span>
                                  <strong className="">
                                    {y.countries.length} Countries
                                  </strong>
                                </h3>
                              </div>
                              <div className="data">
                                <h3>
                                  <span>
                                    <img src={calendar} alt="arrows" />
                                    Data
                                  </span>
                                  <strong>{p.data}</strong>
                                </h3>
                              </div>
                              <div className="data">
                                <h3>
                                  <span>
                                    <img src={global} alt="Calendar" />
                                    Validity
                                  </span>
                                  <strong>{p.day} Days</strong>
                                </h3>
                              </div>
                              <div className="data">
                                <h3>
                                  <span>
                                    <img src={Dollar} alt="Calendar" />
                                    Price
                                  </span>
                                  <strong>{p.price} $</strong>
                                </h3>
                              </div>
                              <Dropdown />
                            </div>
                            <div className="text-center mt-5 mb-5">
                              {!auth.token ? <Link to="/Login" className="submit pt-3 pb-3">Login</Link> : 
                        <>
                        <button
                            className="submit pt-3 pb-3"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => postPayment(p,)}
                          >
                            Buy Now
                            <i className="fa fa-chevron-right"></i>
                          </button>
                          
                          <ModalCode path={a} function={w} />
                          {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header justify-content-center">
                                  <h5 className="modal-title" id="exampleModalLabel">{p.id}</h5>
                                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                  <from className="verify-code">
                                    <div className="form-group">
                                      <label>Code</label>
                                      <input type="text" className="form-control" />
                                    </div>
                                    <button className="submit pt-3 pb-3">
                                      Verify
                                    </button>
                                  </from>
                                </div>
                                <div className="modal-footer justify-content-center">
                                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                  <button
                                    className="submit pt-3 pb-3"
                                    onClick={() => postPayment(p)}
                                  >
                                    Buy Now
                                    <i className="fa fa-chevron-right"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {/* <div className="text-center mt-5 mb-5">
                <Link to="#!" className="submit pt-3 pb-3">
                  Buy Now
                  <i className="fa fa-chevron-right"></i>
                </Link>
              </div> */}
            </div>
          </div>
        ))}
      </div>
      {/* <div className="row">
        <div className="col-md-4">
          <div className="global-package p-3">
            <h2 className="mb-5">
              $39 <span>/month</span>
            </h2>
            <h2>World Wide</h2>
            <p>
              Our goverment backed plan designed to keep your business legaly
              and secure
            </p>
            <div>
              <div className="data">
                <h3>
                  <span>
                    <img src={Data} alt="Global" />
                    Coverage
                  </span>
                  <strong className="number-of-countries">124 Countries</strong>
                </h3>
              </div>
              <div className="data">
                <h3>
                  <span>
                    <img src={calendar} alt="arrows" />
                    Data
                  </span>
                  <strong>1 GB</strong>
                </h3>
              </div>
              <div className="data">
                <h3>
                  <span>
                    <img src={global} alt="Calendar" />
                    Validity
                  </span>
                  <strong>7 Days</strong>
                </h3>
              </div>
            </div>
            <div className="text-center mt-5 mb-5">
              <Link to="#!" className="submit pt-3 pb-3">
                Buy Now
                <i className="fa fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="global-package p-3">
            <h2 className="mb-5">
              $39 <span>/month</span>
            </h2>
            <h2>World Wide</h2>
            <p>
              Our goverment backed plan designed to keep your business legaly
              and secure
            </p>
            <div>
              <div className="data">
                <h3>
                  <span>
                    <img src={Data} alt="Global" />
                    Coverage
                  </span>
                  <strong className="number-of-countries">124 Countries</strong>
                </h3>
              </div>
              <div className="data">
                <h3>
                  <span>
                    <img src={calendar} alt="arrows" />
                    Data
                  </span>
                  <strong>1 GB</strong>
                </h3>
              </div>
              <div className="data">
                <h3>
                  <span>
                    <img src={global} alt="Calendar" />
                    Validity
                  </span>
                  <strong>7 Days</strong>
                </h3>
              </div>
            </div>
            <div className="text-center mt-5 mb-5">
              <Link to="#!" className="submit pt-3 pb-3">
                Buy Now
                <i className="fa fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="global-package p-3">
            <h2 className="mb-5">
              $39 <span>/month</span>
            </h2>
            <h2>World Wide</h2>
            <p>
              Our goverment backed plan designed to keep your business legaly
              and secure
            </p>
            <div>
              <div className="data">
                <h3>
                  <span>
                    <img src={Data} alt="Global" />
                    Coverage
                  </span>
                  <strong className="number-of-countries">124 Countries</strong>
                </h3>
              </div>
              <div className="data">
                <h3>
                  <span>
                    <img src={calendar} alt="arrows" />
                    Data
                  </span>
                  <strong>1 GB</strong>
                </h3>
              </div>
              <div className="data">
                <h3>
                  <span>
                    <img src={global} alt="Calendar" />
                    Validity
                  </span>
                  <strong>7 Days</strong>
                </h3>
              </div>
            </div>
            <div className="text-center mt-5 mb-5">
              <Link to="#!" className="submit pt-3 pb-3">
                Buy Now
                <i className="fa fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Global;
