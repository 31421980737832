import React, { useState, useEffect } from "react";
import "../Assets/Css/Web.css";
import axios from "axios";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";
import Dropdown from "../Package/dropdown";
import Quantity from "../Package/Quantity";
import { useAuth } from "../Login/AuthProvider";
import Modal from "react-bootstrap/Modal";
import Card from "./Card";
import { yellow } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";

function ModalCode(props) {
  const navigate = useNavigate();
    console.log(props.path);
    const [a, seta] = useState({}); 
    const [error, setError] = useState(""); 
    const [success, setSuccess] = useState(""); 
    const auth = useAuth();
    const [formData, setFormData] = useState({
      code: "",
    });
    const SendCode = async (e) => {
      e.preventDefault();
      const response = await axiosConfig
        .post("https://admin.tejwal.co/api/code-check", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setSuccess(res.data.message)
          setError("")
          // Swal.fire({
          //   icon: "success",
          //   title: "code applied successfully",
          //   showConfirmButton: false,
          //   timer: 1500
          // });
        })
        .catch((error) => {
          setError(error.response.data.message)
          setSuccess("")
          // console.log(error.response.data.message);
          // console.log(error.response.data);
          // Swal.fire({
          //   icon: "error",
          //   title: "Oops...",
          //   text: "Something went wrong!",
          // });
        });
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const p = props.path

    console.log(props.path);
    
    const postPayment = async (p) => {
      console.log("pac", p);
      seta(p)
      const response = await axiosConfig({
        method: "post",
        url: "https://admin.tejwal.co/api/get-payment-url",
        data: {
          quantity: props.dataFromChild == "" ? 1 : props.dataFromChild,
          package_id: p.id,
          type: p.type,
          description: p.id,
          price: p.price,
          code: formData.code,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
      })
        .then((res) => {
          // window.location = `${res.data.data}`;
          // navigate("");
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "You Should Login First",
          });
        });
    };
  return (
    <>
      {/* <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title" id="exampleModalLabel">
                {props?.path?.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <from className="verify-code">
                <div className="form-group">
                  <label> enter your discount code</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.code}
                    name="code"
                    onChange={handleChange}
                  />
                </div>
                <p className="error-massage">{error}</p>
                <p className="success-massage">{success}</p>
                <button className="submit pt-3 pb-3" onClick={SendCode}>
                  Verify
                </button>
              </from>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                className="submit pt-3 pb-3"
                data-bs-dismiss="modal"
                // onClick={() => postPayment(p)}
                onClick={(e) => {
                  navigate(`/OrderPage`, {
                    state: { item: p, code: formData, },
                  });
                }}
              >
                Buy Now
                <i className="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
          <div className="modal-header justify-content-center">
              <h5 className="modal-title" id="exampleModalLabel">
                {props?.path?.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <from className="verify-code">
                <div className="form-group">
                  <label> enter your discount code</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.code}
                    name="code"
                    onChange={handleChange}
                  />
                </div>
                <p className="error-massage">{error}</p>
                <p className="success-massage">{success}</p>
                <button className="submit pt-3 pb-3" onClick={SendCode}>
                  Verify
                </button>
              </from>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                className="submit pt-3 pb-3"
                data-bs-dismiss="modal"
                // onClick={() => postPayment(p)}
                onClick={(e) => {
                  navigate(`/OrderPage`, {
                    state: { item: p, code: formData, },
                  });
                }}
              >
                Buy Now
                <i className="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalCode;
