import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Assets/Css/Web.css";
import { useQuery } from "@tanstack/react-query";
import { axiosConfig } from "../Login/axiosAPI";
import { RotatingLines } from "react-loader-spinner";
import Package from "../Package/Package";

function Country({ searchTerm = "" }) {
  const [filteredData, setFilteredData] = useState([]); // Filtered data based on search
  const [limit, setLimit] = useState(9); // Limit for API calls
  const [fullData, setFullData] = useState([]); // Full data for search
  const [className2, setClassName2] = useState("col-md-4");
  const [button, setButton] = useState("text-center mt-5");

  const safeSearchTerm = typeof searchTerm === "string" ? searchTerm.toLowerCase() : "";

  // Function to fetch data
  const fetchPackages = async (page = 1, limit = 9) => {
    const response = await axiosConfig.get(
      `https://admin.tejwal.co/api/airalo/get-packages?page=${page}&limit=${limit}&type=local`
    );
    return response?.data?.data?.data || [];
  };

  // Use react-query's useQuery to fetch data with limit and page
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["packages", limit], // Pass query key as an array
    queryFn: () => fetchPackages(1, limit), // Fetch function
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
    keepPreviousData: true, // Keep previous data while new data is loading
  });

  // Fetch full data when the search term changes
  useEffect(() => {
    if (safeSearchTerm) {
      // Fetch all data with limit 208 when search term is entered
      const fetchAllData = async () => {
        const allData = await fetchPackages(1, 208); // Fetch all data
        setFullData(allData);
      };
      fetchAllData();
    } else {
      setFullData([]); // Clear full data when search term is empty
    }
  }, [safeSearchTerm]);

  // Filter data based on search term
  useEffect(() => {
    if (!safeSearchTerm && data) {
      setFilteredData(data); // Show paginated data if no search term
    } else if (safeSearchTerm && fullData.length > 0) {
      const filtered = fullData.filter((item) =>
        item.title.toLowerCase().includes(safeSearchTerm)
      );
      setFilteredData(filtered);
    }
  }, [safeSearchTerm, data, fullData]);

  // Increment limit by 9 when clicking "See More"
  const handleLoadMore = () => {
    setLimit((prevLimit) => prevLimit + 9);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [s, setS] = useState("d-none");

  const [selectedPackage, setSelectedPackage] = useState(null);

  const handlePackageClick = (packageData) => {
    setSelectedPackage(packageData); // Set the selected package
    setFilteredData([packageData]); // Remove all other packages, show only selected
    setClassName2("col-md-12");
    setButton("d-none ")
  };

  // Handle closing the selected package view
  const handleClosePackage = () => {
    setSelectedPackage(null); 
    setFilteredData(data);
    setLimit(9);
    setClassName2("col-md-4");
    setButton("text-center mt-5")
  }; 

  if (isLoading) {
    return (
      <div className="text-center">
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="text-center">
        <p>Error: {error.message}</p>
      </div>
    );
  }

  return (
    <div className="esims-country">
      <div className="row">
        {filteredData.length > 0 ? (
          filteredData.map((x, index) => (
            <div className={className2} key={index}>
              <a href="#!" className="country-package"  onClick={() => handlePackageClick(x)}>
                <div className="d-flex align-items-center">
                  <img
                    src={x.image.url}
                    className="country-flag"
                    alt="country-flag"
                  />
                  <h3>{x.title}</h3>
                </div>
                <i className="fa fa-arrow-right"></i>
              </a>
              
              {selectedPackage && (
                <div className="package-detail">
                  <div className="d-flex justify-content-end">
                    <button className="close-btn" onClick={handleClosePackage}>
                      Close
                    </button>
                  </div>
                  <Package item={selectedPackage} country={selectedPackage} />
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="col-12 text-center">
            <p>No results found for "{searchTerm}".</p>
          </div>
        )}
      </div>
      <div className={button}>
        <Link to="#!" onClick={handleLoadMore} className="submit pt-3 pb-3">
          See More <i className="fa fa-chevron-right"></i>
        </Link>
      </div>
    </div>
  );
}

export default Country;
