import React, { useState, useEffect, useRef } from "react";
import { Box, Modal, Slider, Button } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import Img from "../Assets/Images/99f960991f3a466f50725556a22c9edd.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "../Assets/Css/Login.css";
import { useAuth } from "../Login/AuthProvider";

const Profile = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const navigate = useNavigate();

  const CropperModal = ({ src, modalOpen, setModalOpen, setPreview }) => {
    const [slideValue, setSlideValue] = useState(10);
    const cropRef = useRef(null);

    //handle save
    const handleSave = async () => {
      if (cropRef) {
        const dataUrl = cropRef.current.getImage().toDataURL();
        const result = await fetch(dataUrl);
        const blob = await result.blob();
        setPreview(URL.createObjectURL(blob));
        setModalOpen(false);
      }
    };

    const boxStyle = {
      width: "300px",
      height: "300px",
      display: "flex",
      flexFlow: "column",
      justifyContent: "center",
      alignItems: "center",
    };
    const modalStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <Modal sx={modalStyle} open={modalOpen}>
        <Box sx={boxStyle}>
          <AvatarEditor
            ref={cropRef}
            image={src}
            style={{ width: "100%", height: "100%" }}
            border={50}
            borderRadius={150}
            color={[0, 0, 0, 0.72]}
            scale={slideValue / 10}
            rotate={0}
          />

          {/* MUI Slider */}
          <Slider
            min={10}
            max={50}
            sx={{
              margin: "0 auto",
              width: "80%",
              color: "cyan",
            }}
            size="medium"
            defaultValue={slideValue}
            value={slideValue}
            onChange={(e) => setSlideValue(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              border: "3px solid white",
              background: "black",
            }}
          >
            <Button
              size="small"
              sx={{ marginRight: "10px", color: "white", borderColor: "white" }}
              variant="outlined"
              onClick={(e) => setModalOpen(false)}
            >
              cancel
            </Button>
            <Button
              sx={{ background: "#5596e6" }}
              size="small"
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const [src, setSrc] = useState(null);

  // preview
  const [preview, setPreview] = useState(null);

  // modal state
  const [modalOpen, setModalOpen] = useState(false);

  // ref to control input element
  const inputRef = useRef(null);

  // handle Click
  const handleInputClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };
  // handle Change
  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
  };

  const LogOut = async (e) => {
    e.preventDefault();
    const response = await axiosConfig
      .post("https://admin.tejwal.co/api/logout", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((res) => {
        navigate("/login");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const updateUser = async (e) => {
    e.preventDefault();
    const response = await axiosConfig
      .post("https://admin.tejwal.co/user/update", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((res) => {})
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };


  let [data, setData] = useState([]);
  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        `https://admin.tejwal.co/api/get-user-data`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    password: "",
    password_confirmation: "",
  });
  const auth = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  console.log(auth.token);


  return (
    <>
      <div className="utility">
        <div className="container">
          <div className="utility-card">
            <h1>My Account</h1>
            <p>
              Get in touch with us today and let us help you with any questions
              or inquiries you may have.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="account">
              <div className="">
                <div className="">
                  <div className="myAccount">
                    {/* <main className="d-flex justify-content-center mb-5">
                      <CropperModal
                        modalOpen={modalOpen}
                        src={src}
                        setPreview={setPreview}
                        setModalOpen={setModalOpen}
                      />
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        ref={inputRef}
                        onChange={handleImgChange}
                      />
                      <div className="img-container">
                      <a href="/" className="add-button" onClick={handleInputClick}>
                        <i className="fa fa-camera add-icon"></i>
                      </a>
                        <img
                          src={
                            preview ||
                            `${Img}`
                          }
                          alt="preview"
                        />
                      </div>
                    </main> */}
                    <h2 className="text-center">
                      <i className="fa fa-user"></i>
                      {data?.name}
                    </h2>
                    <ul className="profile-links">
                      <li>
                        <Link to="/ESIM_History" className="">
                          My eSIMs
                        </Link>
                      </li>
                      <li className="active">
                        <Link to="/Profile" className="">
                          Account
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="" className="">Payment</Link>
                      </li>
                      <li>
                        <Link to="" className="">Refer And Earn</Link>
                      </li> */}
                      <li>
                        <Link to="" onClick={LogOut} className="">
                          Log Out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div>
              <form className="form">
                <div className="row">
                  <div className="col-md-12">
                    <h2>Account Details</h2>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="First">Full Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Full Name"
                        value={data?.name}
                        onChange={handleChange}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Your email"
                        value={data?.email}
                        onChange={handleChange}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="email">Phone Number</label>
                      <input
                        type="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Your phone number"
                        value={data?.phone}
                        onChange={handleChange}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h2>Password</h2>
                  </div>
                  {/* <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="password">Old Password</label>
                      <div className="pass-wrapper">
                        <input
                          placeholder="Password"
                          name="password"
                          className="form-control"
                          type={passwordShown ? "text" : "password"}
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <i
                          onClick={togglePasswordVisiblity}
                          className={
                            passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                          }
                        ></i>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="Confirm_password">New Password</label>
                      <div className="pass-wrapper">
                        <input
                          placeholder="Password"
                          name="password"
                          className="form-control"
                          type={passwordShown ? "text" : "password"}
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <i
                          onClick={togglePasswordVisiblity}
                          className={
                            passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                          }
                        ></i>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="Confirm_password">Confirm Password</label>
                      <div className="pass-wrapper">
                        <input
                          placeholder="Password"
                          name="password_confirmation"
                          className="form-control"
                          type={passwordShown ? "text" : "password"}
                          value={formData.password_confirmation}
                          onChange={handleChange}
                        />
                        <i
                          onClick={togglePasswordVisiblity}
                          className={
                            passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                          }
                        ></i>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center mt-3">
                    <button onClick={updateUser} className="submit">
                      Update <i className="fa fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
