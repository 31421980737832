import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import calendar from "../Assets/Images/arrow-3.png";
import global from "../Assets/Images/calendar.png";
import Data from "../Assets/Images/global.png";
import Dollar from "../Assets/Images/dollar-circle.png";
import Egypt_Flag from "../Assets/Images/Frame 1261155613.png";
import "../Assets/Css/Web.css";
import axios from "axios";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";
import Dropdown from "../Package/dropdown";
import Quantity from "../Package/Quantity";
import { useAuth } from "../Login/AuthProvider";
import Modal from "react-bootstrap/Modal";
import Card from "./Card";
import { yellow } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import ModalCode from "./Modal";

function RegPackage(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(props.show);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [item, setItem] = useState(props.item);
  const [country, setCountry] = useState(props.country);
  const [s, setS] = useState(props.uid);
  const [counter, setCounter] = useState(1);
  const [dataLink, setDataLink] = useState();
  // const [x, setX] = useState(props.show);
  console.log(props.item);
  const auth = useAuth();
  console.log(auth.token);
  const [a, seta] = useState({});

  
const w=(d)=>{
  console.log(d);
}

const postPayment = async (p) => {
  console.log("pac", p);
  // console.log("code", d);
  seta(p)
};

  return (
    <>
      {item.operators.map((y, index) => (
        <div className={s} key={index}>
          <div className="row">
            {y.packages.map((p, index) => (
              <div className="col-md-4" key={index} data-id={p.id}>
                <div className="">
                  <div className="global-package">
                    <div className="">
                      <h1>{p.title}</h1>
                    </div>
                    <div className="p-3">
                      <div className="data">
                        <h3>
                          <span>
                            <img src={Data} alt="Global" />
                            Coverage
                          </span>
                          <strong className="">{country.title}</strong>
                        </h3>
                      </div>
                      <div className="data">
                        <h3>
                          <span>
                            <img src={calendar} alt="arrows" />
                            Data
                          </span>
                          <strong>{p.data}</strong>
                        </h3>
                      </div>
                      <div className="data">
                        <h3>
                          <span>
                            <img src={global} alt="Calendar" />
                            Validity
                          </span>
                          <strong>{p.day} Days</strong>
                        </h3>
                      </div>
                      <div className="data">
                        <h3>
                          <span>
                            <img src={Dollar} alt="Calendar" />
                            Price
                          </span>
                          <strong>{p.price} $</strong>
                        </h3>
                      </div>
                      <Quantity counter={counter} />
                      <Dropdown />
                    </div>
                    <div className="text-center mt-5 mb-5">
                      {!auth.token ? <Link to="/Login" className="submit pt-3 pb-3">Login</Link> : 
                        <>
                          <button
                            className="submit pt-3 pb-3"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => postPayment(p,)}
                          >
                            Buy Now
                            <i className="fa fa-chevron-right"></i>
                          </button>
                          <ModalCode path={a} function={w} />
                          {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header justify-content-center">
                                  <h5 className="modal-title" id="exampleModalLabel">{p.id}</h5>
                                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                  <from className="verify-code">
                                    <div className="form-group">
                                      <label>Code</label>
                                      <input type="text" className="form-control" />
                                    </div>
                                    <button className="submit pt-3 pb-3">
                                      Verify
                                    </button>
                                  </from>
                                </div>
                                <div className="modal-footer justify-content-center">
                                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                  <button
                                    className="submit pt-3 pb-3"
                                    onClick={() => postPayment(p)}
                                  >
                                    Buy Now
                                    <i className="fa fa-chevron-right"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

export default RegPackage;
