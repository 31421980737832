import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Assets/Css/Login.css";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";

function NewPassword() {
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };
    const location = useLocation();
    const { otp, email } = location.state || {};

    console.log(otp);
    console.log(email);

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      email: email,
      otp: otp,
      password: "",
      password_confirmation: "",
    });
    
    const handleReset = async (e) => {
      e.preventDefault();
      const response = await axiosConfig
        .post("https://admin.tejwal.co/api/reset-password", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          navigate(`/Login`);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };


  return (
    <>
      <div className="login">
        <form className="form" action="/">
          <div className="row">
            <div className="col-md-12">
              <div className="forget">
                <Link to="/Login" className="back">
                  <i className="fa fa-chevron-left"></i>Back to login
                </Link>
                <h1>Set Password</h1>
                <p>Enter Your New Password</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="pass-wrapper">
                  <input
                    placeholder="Password"
                    name="password"
                    className="form-control"
                    type={passwordShown ? "text" : "password"}
                    // value={inputFields.password}
                    onChange={handleChange}
                  />
                  <i
                    onClick={togglePasswordVisiblity}
                    className={passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}
                  ></i>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="password_confirmation">Confirm Password</label>
                <div className="pass-wrapper">
                  <input
                    placeholder="Password"
                    name="password_confirmation"
                    className="form-control"
                    type={passwordShown ? "text" : "password"}
                    // value={inputFields.password}
                    onChange={handleChange}
                  />
                  <i
                    onClick={togglePasswordVisiblity}
                    className={passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}
                  ></i>{" "}
                </div>
              </div>
              {/* {errors.password ? (
                <p className="error">
                  Password should be at least 5 characters long
                </p>
              ) : null} */}
            </div>
          </div>
          <button type="submit" className="submit" onClick={handleReset}>
            Submit <i className="fa fa-chevron-right"></i>
          </button>
        </form>
      </div>
    </>
  );
}

export default NewPassword;
