import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/Images/tejwallightlogo.png";
import Step1 from "../Assets/Images/Frame 1261155632.png";
import step1img from "../Assets/Images/1.png";
import Step2 from "../Assets/Images/Frame 1261155632 (1).png";
import step2img from "../Assets/Images/2.png";
import Step3 from "../Assets/Images/Frame 1261155632 (2).png";
import step3img from "../Assets/Images/3.png";
import Hero from "../Assets/Images/Group 1261152707.png";
import Egypt from "../Assets/Images/Rectangle 34624187.png";
import Dubai from "../Assets/Images/Rectangle 34624188.png";
import Italy from "../Assets/Images/Rectangle 34624189.png";
import Help from "../Assets/Images/Rectangle 34624190.png";
import Wifi from "../Assets/Images/wifi.png";
import Dollar from "../Assets/Images/dollar-circle.png";
import cpu from "../Assets/Images/cpu.png";
import Main from "../Assets/Images/Group 1261152706.png";
import Africa_Flag from "../Assets/Images/image 5 (1).png";
import Asia_Flag from "../Assets/Images/image 6 (1).png";
import Middle_Flag from "../Assets/Images/image 6.png";
import Europe_Flag from "../Assets/Images/image 7.png";
import America_Flag from "../Assets/Images/image 8.png";
import Latin_Flag from "../Assets/Images/image 9.png";
import Islands_Flag from "../Assets/Images/image 10.png";
import "../Assets/Css/Web.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import FAQ from "./FAQ";
import { Autoplay } from "swiper/modules";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Country from "./Country";
import Global from "./Global";
import axios from "axios";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";
import DynamicGridLayout from "./DynamicGridLayout";
import Regional from "./regional";
import { useAuth } from "../Login/AuthProvider";

function Home() {
  const auth = useAuth();
    let [data, setData] = useState([]);
    const getData = async () => {
        try {
            const response = await axiosConfig.get(
              `https://admin.tejwal.co/api/packages-cards`
            );
            setData(response.data.data);
            console.log(response.data.data);
        } catch (error) {
        }
    };

  const [data2, setData2] = useState([]); // All data
  const [filteredData, setFilteredData] = useState([]); 
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false); 
  const getData2 = async () => {
    setLoading(true);
    setPage((prevPage) => prevPage + 1); // Increment page
    try {
      const response = await axiosConfig.get(
        `https://admin.tejwal.co/api/airalo/get-packages?page=${page + 1}&limit=208&type=local`
      );
      const newData = response?.data?.data?.data || [];
      console.log(response?.data?.data?.data);
      setData2((prevData) => [...prevData, ...newData]);
      setFilteredData((prevData) => [...prevData, ...newData]); // Update filtered data
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    getData2();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    console.log(value);
  }
    return (
      <>
        <div className="hero">
          <img src={Hero} alt="Hero" className="hero-img" />
          <div className="container">
            <div className="hero-card">
              <h1>
                Welcome To <img src={Logo} alt="logo" />
              </h1>
              <h2>Boundless Connectivity Unleashed</h2>
              <p>
                Connect without limits. Our platform revolutionizes
                connectivity, empowering you to effortlessly reach new heights
              </p>
              {/* <a href="#!" className="submit">
                Explore Our Package <i className="fa fa-chevron-right"></i>
              </a> */}
              {!auth.token ? (
                <div className="mt-4">
                  <Link to="/Login" className="submit pt-3 pb-3">
                    Sign In <i className="fa fa-chevron-right"></i>
                  </Link>
                  <Link to="/Login" className="submit pt-3 pb-3 ms-3 me-3">
                    Sign Up <i className="fa fa-chevron-right"></i>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="package">
          <div className="container">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              loop={true}
              modules={[Autoplay]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                440: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              className="mySwiper"
            >
              {data?.map((x, index) => (
                <SwiperSlide key={index}>
                  <div className="">
                    <div className="package-eSim">
                      <h2>{x.title}</h2>
                      <h3>
                        Started At <span>{x.start_at}$</span>
                      </h3>
                      <ul>
                        <li>
                          <i className="fa fa-check"></i>
                          <h4>
                            Initial Balance <span>{x.data_only}</span>
                          </h4>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <h4>
                            Data Only <span>{x.initial_balance}</span>
                          </h4>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <h4>
                            Internet Access <span>{x.internet_access}</span>
                          </h4>
                        </li>
                      </ul>
                      <a href="#eSIMs" className="submit mt-3">
                        Get It Now <i className="fa fa-chevron-right"></i>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="eSIMs" id="eSIMs">
          <div className="container">
            <div className="text-center">
              <h2 className="main-heading">
                Discover eSIM Connectivity Worldwide
              </h2>
              <p className="main-description">
                Choose your destination first, then a data plan according to
                your needs.
              </p>
              <form>
                <div className="form-group">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <Tabs>
              <TabList>
                <Tab>Local eSIMs</Tab>
                <Tab>Regional eSIMs</Tab>
                <Tab>Global eSIMs</Tab>
              </TabList>

              <TabPanel>
                <Country searchTerm={searchTerm} />
              </TabPanel>
              <TabPanel>
                {/* <div className="row">
                  <div className="col-md-4">
                    <div className="country-package">
                      <div className="d-flex align-items-center">
                        <img
                          src={Africa_Flag}
                          className="country-flag"
                          alt="country-flag"
                        />
                        <h3>Africa</h3>
                      </div>
                      <i className="fa fa-arrow-right"></i>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="country-package">
                      <div className="d-flex align-items-center">
                        <img
                          src={Asia_Flag}
                          className="country-flag"
                          alt="country-flag"
                        />
                        <h3>Asia</h3>
                      </div>
                      <i className="fa fa-arrow-right"></i>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="country-package">
                      <div className="d-flex align-items-center">
                        <img
                          src={Middle_Flag}
                          className="country-flag"
                          alt="country-flag"
                        />
                        <h3>Middle East</h3>
                      </div>
                      <i className="fa fa-arrow-right"></i>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="country-package">
                      <div className="d-flex align-items-center">
                        <img
                          src={Europe_Flag}
                          className="country-flag"
                          alt="country-flag"
                        />
                        <h3>Europe</h3>
                      </div>
                      <i className="fa fa-arrow-right"></i>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="country-package">
                      <div className="d-flex align-items-center">
                        <img
                          src={America_Flag}
                          className="country-flag"
                          alt="country-flag"
                        />
                        <h3>North America</h3>
                      </div>
                      <i className="fa fa-arrow-right"></i>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="country-package">
                      <div className="d-flex align-items-center">
                        <img
                          src={Latin_Flag}
                          className="country-flag"
                          alt="country-flag"
                        />
                        <h3>Latin America</h3>
                      </div>
                      <i className="fa fa-arrow-right"></i>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="country-package">
                      <div className="d-flex align-items-center">
                        <img
                          src={Islands_Flag}
                          className="country-flag"
                          alt="country-flag"
                        />
                        <h3>Caribbean Islands</h3>
                      </div>
                      <i className="fa fa-arrow-right"></i>
                    </div>
                  </div>
                </div> */}
                <Regional />
              </TabPanel>
              <TabPanel>
                <Global />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <div className="work">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="main-heading">How it works !</h2>
                <p className="main-description">
                  How تجوال Works - Simple Steps to Seamless Connectivity
                </p>
              </div>
              <div className="col-md-4">
                <div className="step">
                  <div className="h-100">
                    <img src={Step1} alt="Step" className="step-1-img" />
                    <h3>Select eSIM</h3>
                    <p>
                      Pick the eSIM option that suits your travel needs from
                      تجوال's offerings.
                    </p>
                  </div>
                  <img src={step1img} alt="Step" className="step-img" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="step">
                  <div className="h-100">
                    <img src={Step2} alt="Step" className="step-1-img" />
                    <h3>Activate eSIM</h3>
                    <p>
                      Follow the simple activation process to start enjoying
                      seamless connectivity with تجوال's eSIM.
                    </p>
                  </div>
                  <img src={step2img} alt="Step" className="step-img" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="step">
                  <div className="h-100">
                    <img src={Step3} alt="Step" className="step-1-img" />
                    <h3>Enjoy Connectivity</h3>
                    <p>
                      Stay connected wherever you go . Explore new horizons
                      without worrying about connectivity issues.
                    </p>
                  </div>
                  <img src={step3img} alt="Step" className="step-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="explore">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="main-heading">Explore Your Destination</h2>
                <p className="main-description">
                  How تجوال Works - Simple Steps to Seamless Connectivity
                </p>
              </div>
              <DynamicGridLayout />
              {/* <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    <div className="country-card mb-3">
                      <Link to="/Destination">
                        <img src={Dubai} alt="Dubai" />
                        <div className="country-layout">
                          <h3>Dubai</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="country-card mb-3">
                      <Link to="/Destination">
                        <img src={Italy} alt="Italy" />
                        <div className="country-layout">
                          <h3>Italy</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="country-card">
                      <img src={Help} alt="Help" />
                      <div className="country-layout">
                        <h3>We Help You Wherever You Go</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="choose">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center mb-5">
                <h2 className="main-heading">Why Choose تجوال !</h2>
                <p className="main-description">
                  Enjoy reliable and affordable internet in your trips. We get
                  you covered.
                </p>
              </div>
              <div className="col-md-6 text-center">
                <img src={Main} alt="Main" className="plant-img" />
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <ul>
                  <li>
                    <img src={cpu} alt="cpu" />
                    <div>
                      <h3>Easy eSIM</h3>
                      <p>
                        Pick the eSIM option that suits your travel needs from
                        تجوال's offerings.
                      </p>
                    </div>
                  </li>
                  <li>
                    <img src={Dollar} alt="Dollar" />
                    <div>
                      <h3>Smart Pricing</h3>
                      <p>
                        Pick the eSIM option that suits your travel needs from
                        تجوال's offerings.
                      </p>
                    </div>
                  </li>
                  <li>
                    <img src={Wifi} alt="Wifi" />
                    <div>
                      <h3>Better Connectivity</h3>
                      <p>
                        Pick the eSIM option that suits your travel needs from
                        تجوال's offerings.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="faqs">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center mb-4">
                <h2 className="main-heading">Frequently Asked Questions</h2>
                <p className="main-description">
                  Find helpful information to answer your questions
                </p>
              </div>
              <FAQ />
              <div className="text-center mt-3">
                <Link to="/FAQ_page" className="submit">
                  More <i className="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Home;
